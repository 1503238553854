<template>
    <like-link :likeLayout="likeLayout" :likeData="likeData"></like-link>
</template>

<script>
import likeUtil from "@/components/like/likeUtil";
import LikeLink from "@/components/like/LikeLink";
import EventBus from "@/utils/event-bus";

export default {
    name: "LikeMemberComponent",
    mixins: [likeUtil],
    components: {LikeLink},
    inject: [],
    provide() {
        return {}
    },
    props: {
        likeType: '',
        likeData: {default: () => {}},
    },
    data() {
        return {
            likeLayout: '',
            writeFunc: '',
            keyName: '',
        }
    },
    beforeRouterEnter() {
    },
    created() {
        this.initConfig();
    },
    mounted() {
        EventBus.$on('likeMember', this.like);
    },
    beforeDestroy() {
        EventBus.$off('likeMember');
    },
    destroyed() {
    },
    computed: {},
    methods: {
        initConfig() {
            let config = this.returnLikeConfig(this.likeType)
            this.likeLayout = config.likeLayout;
            this.writeFunc = config.writeFunc;
            this.keyName = config.keyName;
        },
        like() {
            let params = {}
            params.follow_id = this.likeData.mb_no;
            EventBus.$emit('apiLoading',true)
            try {
                this.$api.$member[this.writeFunc](params).then(res => res.Data.Result).then(res => {
                    EventBus.$emit('apiLoading',false)
                    if (res.Info.type === 1) {
                        this.likeAfterFunc()
                    }
                })
            }catch (e) {
                EventBus.$emit('apiLoading',false)
                console.log(e)
            }

        },
        likeAfterFunc() {
            if (!this.likeData.likeStatus) {
                this.likeData.likeCnt = this.likeData.likeCnt + 1;
            } else {
                this.likeData.likeCnt = this.likeData.likeCnt - 1;
            }
            this.likeData.likeStatus = !this.likeData.likeStatus;
            this.$forceUpdate();
        }

    },
    watch: {},
}
</script>

<style scoped>

</style>
