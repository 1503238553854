<template>
    <div>
        <div class="creator_detail_title">
            {{ $t('creator') }}
        </div>
        <div class="creator_detail_backImg">
            <img :src="returnBackground()" width="1200" height="310" alt="">
        </div>
        <div class="creator_detail_info_box">
            <div class="creator_detail_profileImg">
                <div class="profileImg">
                    <img :src="returnProfile()" width="180" height="180" alt="">
                </div>
                <div class="user_util_box">
                    <div class="user_user_like_wrap">
                        <div class="user_like_box">
                            <like-member-component v-if="!isEmptyData(memberData)" class="in_block" :likeType="'memberDetail'" :likeData="memberData"></like-member-component>
<!--                            <div class="like_img_wrap">
                                <div class="like_img"></div>
                                <span class="like_count">{{ numFormat(memberData.likeCnt) }}</span>
                            </div>-->
                        </div>
                        <div class="right_line"></div>
                        <div class="user_item_count_box">
                            <span class="item_text">{{ $t('en_item') }}</span>
                            <span class="item_count">{{ numFormat(memberData.itemCnt) }}</span>
                        </div>
                    </div>
                    <div class="user_share_box">
                        <share-component class="in_block" :member-data="memberData"></share-component>
                    </div>
                </div>
            </div>
            <div class="creator_detail_nick">
                <span class="creator_nick">{{ memberData.mb_nick }}</span>
                <div class="creator_detail_badge_box curator" v-if="memberData.g_idx === 2">
                    <span>{{ $t('en_curator') }}</span>
                </div>
                <div class="creator_detail_badge_box special" v-if="memberData.mb_premium === 1">
                    <span>{{ $t('en_special') }}</span>
                </div>
                <div class="creator_btn_box" v-if="memberData.g_idx === 2">
                    <button class="creator_btn curator" @click="openModal('curation-history-modal',memberData.mb_no)"><span>{{ $t('curation_history') }}</span></button>
                    <button class="creator_btn request" v-if="getMemberType() === 'normal'" @click="movePage(`/curation/${memberData.mb_uuid}/request`)"><span>{{ $t('c_request') }}</span></button>
                </div>
            </div>
            <div class="creator_detail_url">
                <div class="detail_url" v-if="!isEmptyData(memberData.mb_sns_1)">
                    <a :href="returnUrl(memberData.mb_sns_1)" target="_blank">{{ returnUrl(memberData.mb_sns_1) }}</a>
                </div>
                <div class="detail_url" v-if="!isEmptyData(memberData.mb_sns_2)">
                    <a :href="returnUrl(memberData.mb_sns_2)" target="_blank">{{ returnUrl(memberData.mb_sns_2) }}</a>
                </div>
                <div class="detail_url" v-if="!isEmptyData(memberData.mb_sns_3)">
                    <a :href="returnUrl(memberData.mb_sns_3)" target="_blank">{{ returnUrl(memberData.mb_sns_3) }}</a>
                </div>
            </div>
            <div class="create_detail_intro_wrap">
                <input type="checkbox" id="expanded" v-model="onceMore">
                <div class="creator_detail_intro" v-html="replaceBr(memberData.mb_introduce)"></div>
                <!--<div class="intro_more" :class="{'off':!onceMore}">
                    <label for="expanded" role="button" class="content_g_button" :class="{'off':!onceMore}" v-if="!isEmptyData(memberData.mb_introduce)"></label>
                </div>-->
            </div>
        </div>
    </div>
</template>

<script>
import imageOption from "@/mixins/imageOption";
import LikeMemberComponent from "@/components/like/LikeMemberComponent";
import ShareComponent from "@/components/share/ShareComponent";
import AuthMixins from "@/mixins/AuthMixins";
import {mapState} from "vuex";
import util from "@/mixins/util";
import EventBus from "@/utils/event-bus";

export default {
    name: "MemberDetailUserLayout",
    mixins: [imageOption, AuthMixins],
    components: {
        LikeMemberComponent,
        ShareComponent,
    },
    inject: [],
    provide() {
        return{

        }
    },
    props: {
        memberData: {default: () => {}},
    },
    data() {
        return{
            onceMore: false,
        }
    },
    beforeRouterEnter() {},
    created() {
      this.setMetaTitle();
    },
    mounted() {
        this.setMoreContent();

    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
        ...mapState({
            UserInfo: state => state.userInfo.UserInfo,
        })
    },
    methods:{
        setMetaTitle(){
          if (!util.isEmpty(this.memberData) && this.memberData.hasOwnProperty('mb_nick')) {
            EventBus.$emit('setMetaTitle', this.memberData.mb_nick);
          }
        },
        movePage(url){
            this.$router.push(url);
        },

        openModal(name,key){
            this.$modal.show(name,{memberKey:key});
        },
        numFormat(num) {
            if(util.isEmpty(num) || isNaN(num)) {
                return 0;
            }
            return util.Number.numFormat(num);
        },
        isEmptyData(data) {
            return util.isEmpty(data);
        },
        replaceBr(content) {
            if (util.isEmpty(content)) {
                return '';
            }
            return content.replace(/(\n|\r\n)/g, '<br>');
        },
        setCuratorBtnText() {
            if(this.memberData.g_idx === 2 ) {
                return this.$t('curation_history')
            }else if(!this.isEmptyData(this.memberData.c_idx)) {
                return this.$t('curator_ready')
            }
            return this.$t('curator_apply')
        },
        returnBackground() {
            if(util.isEmpty(this.memberData.Files) || util.isEmpty(this.memberData.Files.ProfileBack)) {
                return "";
            }
            return `${this.memberData.Files.ProfileBack[0].org_url}?${this.setImageOptions(1200, 310, 'jpg')}`;
        },
        returnProfile() {
            if(util.isEmpty(this.memberData.Files) || util.isEmpty(this.memberData.Files.Profile)) {
                return "";
            }
            return `${this.memberData.Files.Profile[0].org_url}?${this.setImageOptions(180, 180, 'jpg')}`;
        },
        returnUrl(url) {
            if(util.isEmpty(url)) {
                return "";
            }
            let expUrl = /^http[s]?\:\/\//i;
            if(!expUrl.test(url)) {
                return `http://${url}`;
            }
            return url;
        },
        setMoreContent() {
            if(util.isEmpty(this.memberData.mb_introduce)) {
                return false;
            }
            const ps = document.querySelectorAll('.creator_detail_intro');
            if(ps.length === 0) {
                setTimeout(() => {
                    this.setMoreContent();
                }, 200)
                return false;
            }
            let $this = this;
            ps.forEach(p => {
                p.style.lineHeight = '22.4px';
            })
            this.onceObserver = new ResizeObserver(entries => {
                for (let entry of entries) {
                    let divHeight = entry.target.scrollHeight
                    let lineHeight = parseInt(entry.target.style.lineHeight);
                    let lines = Math.floor(divHeight / lineHeight);
                    if(!$this.onceMore && lines > 5){
                        entry.target.classList['add']('off');
                    }
                }
            });

            ps.forEach(p => {
                this.onceObserver.observe(p);
            });
            return true;
        },

    },
    watch: {
        'memberData': {
            deep: true,
            immediate: true,
            handler: function (val, oldVal) {
                this.setMoreContent();
                this.setMetaTitle();
            }
        }
    },
}
</script>

<style scoped>

</style>
