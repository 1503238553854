<template>
  <button class="share_img_wrap" @click="openShareModal">
    <div class="share_img"></div>
  </button>
</template>

<script>

export default {
    name: "ShareComponent",
    components: {
    },
    provide: function() {
        return {
        }
    },
    props: {
        memberData:{}
    },
    data() {
        return{
        }
    },
    created() {
    },
    mounted() {
    },
    beforeDestroy() {
    },
    destroyed() {},
    computed: {
    },
    methods: {
        openShareModal() {
            this.$modal.show('share-modal', {memberData: this.memberData})
        }
    },
}
</script>

<style scoped>

</style>
